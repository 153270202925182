import React from 'react'
import Link from '../../common/Link'

const ServiceWebRequirementCta = () => (
  <section className="service-web bg-dark py-5">
    <div className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="lead text-center">
            <strong>
            Fullt funktionell webbapp på bara 4 veckor?&nbsp;
            </strong>
            <br />
            <div className="d-inline text-muted">
              Vår<h3 className="seo-span">design- och produktionsprocess för webbappar</h3>
              är mycket snabbare eftersom &nbsp;
              <strong className="seo-regular">vårt team</strong>&nbsp;
              jobbar effektivt och agilt.
            </div>
            <br />
            <Link to="/estimate-app-cost/" className="btn btn-info block-xs m-3">
              Diskutera ditt krav
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceWebRequirementCta
