import React from 'react'
import Link from '../../common/Link'
 
const ServiceWebTags = () => (
 <section className="service-web bg-faded py-5">
   <div className="container">
     <div className="row mb-5">
       <div className="col-sm-12 col-md-4">
         <h2 className="text-sm-center text-md-right">
           <span className="text-muted seo-regular">Ett agilt
</span>
           <br />
           <strong>utvecklarteam</strong>
         </h2>
       </div>
       <div className="col-sm-12 col-md-8">
         <p>
           Vi ser alltid till att tilldela personer med rätt kompetens för just ditt projekt – vi tror att ett välfungerande team är en framgångsgaranti. Varje projektgrupp består av programmerare, designers, UI-experter och front-end proffs. Vill du veta mer -
           <Link to="/about/team/">{' '}läs mer om vårt team.</Link>.
         </p>
       </div>
     </div>
 
     <div className="row">
       <div className="col-sm-12 col-md-4">
         <h2 className="text-sm-center text-md-right">
           <span className="text-muted seo-regular">Webbappar</span>
           <br />
           för storföretag
         </h2>
       </div>
       <div className="col-sm-12 col-md-8">
         <div className="seo-p">
           Vi
           <h2 className="seo-span">designar webbappar</h2>
           som kan hjälpa dig uppnå dina marknadsföringsmål oavsett vilken bransch du jobbar i: e-learning, ERP, BPA, sociala medier, e-handel, Gaming, innovation, health-tech eller livsstil.
         </div>
         <ul className="list-unstyled list-inline list-badges">
           <li>
             <span className="badge badge-light">.NET</span>
           </li>
           <li>
             <span className="badge badge-light">.NET MVC</span>
           </li>
           <li>
             <span className="badge badge-light">Ruby on Rails</span>
           </li>
           <li>
             <span className="badge badge-light">HTML5</span>
           </li>
           <li>
             <span className="badge badge-light">ExpressJS</span>
           </li>
           <li>
             <span className="badge badge-light">Zend</span>
           </li>
           <li>
             <span className="badge badge-light">ASO.NET</span>
           </li>
           <li>
             <span className="badge badge-light">Angular JS</span>
           </li>
           <li>
             <span className="badge badge-light">Prototype testing</span>
           </li>
           <li>
             <span className="badge badge-light">QA testing</span>
           </li>
           <li>
             <span className="badge badge-light">Responsive web design</span>
           </li>
           <li>
             <span className="badge badge-light">Support & Maintenance</span>
           </li>
           <li>
             <span className="badge badge-light">Static websites</span>
           </li>
           <li>
             <span className="badge badge-light">
               Mobile-friendly websites
             </span>
           </li>
           <li>
             <span className="badge badge-light">Service integrations</span>
           </li>
         </ul>
         <div className="clearfix" />
       </div>
     </div>
 
     <div className="row">
       <div className="col-sm-12 col-md-4" />
       <div className="col-sm-12 col-md-8" />
     </div>
   </div>
 </section>
)
 
export default ServiceWebTags
 
