import React from 'react'
import { Category, scrollToForm } from '../../contact/ContactForm'

const ServiceWebCta = () => (
  <section className="service-web">
    <div className="container">
      <div className="row py-5">
        <div className="col-sm-12 col-md-4 col-lg-6">
          <div className="lead text-sm-center text-md-right">
            <h3 className="seo-span seo-strong text-muted">
              Skräddarsydda webbappar.
            </h3>
            <br />
            <span className="text-service">
               Bäst programkvalitet &nbsp;
              <strong className="span-regular"> till offshore-priser</strong>
            </span>
            <br />
            <a
              href="#contact-partial"
              onClick={event => {
                event.preventDefault()
                scrollToForm(Category.WebApps, 0, 500)
              }}
              className="btn btn-outline-info my-2"
              id="custom-web-app-talk-btn"
            >
              Kolla vårt erbjudande!
            </a>
          </div>
        </div>
        <div className="col-sm-12 col-md-8 col-lg-6">
          <div className="seo-p">
            Våra<h4 className="seo-span">  programmerare</h4> har bred kompetens och stor erfarenhet 
            av att specialdesigna webbappar för våra kunder runtom i världen.
            &nbsp;
            Vi erbjuder back-end modernisering och molnlösningar. 
            Utöver detta kan vi uppgradera en befintlig front-end och 
            därmed öka programvarans hastighet. Zavens programutvecklare är 
            produktiva, kostnadseffektiva och lyhörda för kundens behov. 
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceWebCta
