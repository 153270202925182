
import React from 'react'
 
const MvpReasons = () => (
 <section className="section bg-faded">
   <div className="container">
     <h2 className="text-center mt-2">
       Ge din startup tillgång till full-stack-ingenjörer
     </h2>
     <div className="card-group card-group-transparent my-2 mvp-reasons">
       <div className="card">
         <div className="card-body">
           Snabb prototyputveckling
           <br />
           <em className="text-muted">
             <abbr title="Minimum Viable Product">MVP</abbr> kan levereras inom 12-16 veckor
           </em>
         </div>
       </div>
       <div className="card">
         <div className="card-body">
           Vårt teams fulla support
           <br />
           <em className="text-muted">
             analytiker, PM, programmerare, designers, testare
           </em>
         </div>
       </div>
       <div className="card">
         <div className="card-body">
           Snygg och användarvänlig UI/UX <br />
           <em className="text-muted">
             designad av our&nbsp;
             <span className="text-nowrap">in-house</span> våra proffs
           </em>
         </div>
       </div>
       <div className="card">
         <div className="card-body">
           <h3 className="seo-strong m-0">Agil programmering</h3>
           <br />
           <em className="text-muted">styrd enligt SCRUM</em>
         </div>
       </div>
       <div className="card">
         <div className="card-body">
           Långtidssupport
           <br />
           <em className="text-muted">
             låt produkten utvecklas vidare efter MVP-perioden
           </em>
         </div>
       </div>
       <div className="card">
         <div className="card-body">
          Fjärrstyrning som fungerar väl
           <br />
           <em className="text-muted">
             för
             <h3 className="seo-span">outsourcing</h3> programmering utan gränser
           </em>
         </div>
       </div>
     </div>
   </div>
 </section>
)
 
export default MvpReasons
 
